var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.operatorsFiltered,"mobile-breakpoint":0,"loading":_vm.loading,"disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{staticClass:"mt-0 mx-0 pt-4 pb-0 pb-4",staticStyle:{"background-color":"#eeeeee"},attrs:{"no-gutter":""}},[_c('v-col',{staticClass:"d-flex mb-n3",attrs:{"cols":"12"}},[_c('h1',{staticClass:"mr-4"},[_vm._v("OPERATORS")]),(
                            _vm.user.permissions &&
                                _vm.user.permissions.includes(
                                    'createOperators'
                                ) &&
                                _vm.user.permissions.includes('createUsers')
                        )?_c('v-btn',{staticClass:"mx-2",attrs:{"rounded":"","color":"primary"},on:{"click":_vm.openCreateOperator}},[_c('v-icon',[_vm._v(" mdi-plus ")]),_vm._v(" "+_vm._s(_vm.$vuetify.breakpoint.smAndDown ? '' : 'NEW')+" ")],1):_vm._e()],1)],1)]},proxy:true},{key:`header.name`,fn:function({ header }){return [_c('v-text-field',{staticClass:"pb-2 pt-3 mt-2",attrs:{"label":header.text,"dense":"","prepend-icon":"mdi-filter"},model:{value:(_vm.searchByName),callback:function ($$v) {_vm.searchByName=$$v},expression:"searchByName"}})]}},{key:`item.id`,fn:function({ item }){return [_c('div',{staticClass:"d-flex justify-center"},[_c('p',{staticClass:"my-0"},[_vm._v(_vm._s(item.id))])])]}},{key:`item.name`,fn:function({ item }){return [_c('div',{staticClass:"d-flex"},[_c('p',{staticClass:"my-0 pl-8"},[_vm._v(_vm._s(item.name))])])]}},{key:`item.role`,fn:function({ item }){return [_c('div',{staticClass:"d-flex justify-center"},[_c('p',{staticClass:"my-0"},[_vm._v(_vm._s(item.role))])])]}},{key:`item.process`,fn:function({ item }){return [(item.process)?_c('div',{staticClass:"d-flex justify-center"},[_c('p',{staticClass:"my-0"},[_vm._v(_vm._s(item.process.name))])]):_vm._e()]}},{key:`item.sign_code`,fn:function({ item }){return [_c('div',{staticClass:"d-flex justify-end align-center"},[_c('p',{staticClass:"my-0"},[_vm._v(_vm._s(item.code))]),(
                        _vm.user.permissions &&
                            _vm.user.permissions.includes('editOperators') &&
                            item.signCode
                    )?_c('v-btn',{staticClass:"ml-2",attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.returnSignCode(item)}}},[(item.showCode)?_c('v-icon',[_vm._v("mdi-eye-off")]):_c('v-icon',[_vm._v("mdi-eye")])],1):_vm._e()],1)]}},{key:`item.actions`,fn:function({ item }){return [_c('div',{staticClass:"d-flex justify-center"},[(
                        _vm.user.permissions &&
                            _vm.user.permissions.includes('editOperators')
                    )?_c('v-btn',{attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.openEditOperator(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1):_vm._e()],1)]}}],null,true)}),_c('v-dialog',{attrs:{"retain-focus":false,"persistent":"","width":"600px"},model:{value:(_vm.openCreateForm),callback:function ($$v) {_vm.openCreateForm=$$v},expression:"openCreateForm"}},[(_vm.openCreateForm)?_c('UserForm',{attrs:{"title":"New Operator","create-form":true},on:{"closeDialog":_vm.closeEditOperator,"addUser":_vm.addOperator}}):_vm._e()],1),_c('v-dialog',{attrs:{"retain-focus":false,"persistent":"","width":"600px"},model:{value:(_vm.openEditForm),callback:function ($$v) {_vm.openEditForm=$$v},expression:"openEditForm"}},[(_vm.openEditForm)?_c('OperatorsForm',{attrs:{"createForm":false,"operator":_vm.selectedOperator,"originalOperator":_vm.originalOperator,"processesSettings":_vm.processesSettings},on:{"addOperator":_vm.addOperator,"replaceOperator":_vm.replaceOperator,"close":_vm.closeEditOperator}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }