<template>
    <v-container fluid>
        <v-data-table
            :headers="headers"
            :items="operatorsFiltered"
            class="elevation-0"
            :mobile-breakpoint="0"
            :loading="loading"
            disable-pagination
            hide-default-footer
        >
            <template v-slot:top>
                <v-row
                    no-gutter
                    class="mt-0 mx-0 pt-4 pb-0 pb-4"
                    style="background-color: #eeeeee"
                >
                    <v-col cols="12" class="d-flex mb-n3">
                        <h1 class="mr-4">OPERATORS</h1>
                        <v-btn
                            v-if="
                                user.permissions &&
                                    user.permissions.includes(
                                        'createOperators'
                                    ) &&
                                    user.permissions.includes('createUsers')
                            "
                            rounded
                            color="primary"
                            @click="openCreateOperator"
                            class="mx-2"
                        >
                            <v-icon>
                                mdi-plus
                            </v-icon>
                            {{ $vuetify.breakpoint.smAndDown ? '' : 'NEW' }}
                        </v-btn>
                    </v-col>
                </v-row>
            </template>
            <!--HEADERS-->
            <template v-slot:[`header.name`]="{ header }">
                <v-text-field
                    class="pb-2 pt-3 mt-2"
                    :label="header.text"
                    v-model="searchByName"
                    dense
                    prepend-icon="mdi-filter"
                />
            </template>
            <!--ITEMS-->
            <template v-slot:[`item.id`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="my-0">{{ item.id }}</p>
                </div>
            </template>
            <template v-slot:[`item.name`]="{ item }">
                <div class="d-flex">
                    <p class="my-0  pl-8">{{ item.name }}</p>
                </div>
            </template>
            <template v-slot:[`item.role`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="my-0">{{ item.role }}</p>
                </div>
            </template>
            <template v-slot:[`item.process`]="{ item }">
                <div class="d-flex justify-center" v-if="item.process">
                    <p class="my-0">{{ item.process.name }}</p>
                </div>
            </template>
            <template v-slot:[`item.sign_code`]="{ item }">
                <div class="d-flex justify-end align-center">
                    <p class="my-0">{{ item.code }}</p>
                    <v-btn
                        small
                        icon
                        @click="returnSignCode(item)"
                        v-if="
                            user.permissions &&
                                user.permissions.includes('editOperators') &&
                                item.signCode
                        "
                        class="ml-2"
                    >
                        <v-icon v-if="item.showCode">mdi-eye-off</v-icon>
                        <v-icon v-else>mdi-eye</v-icon>
                    </v-btn>
                </div>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
                <div class="d-flex justify-center">
                    <v-btn
                        small
                        icon
                        @click="openEditOperator(item)"
                        v-if="
                            user.permissions &&
                                user.permissions.includes('editOperators')
                        "
                    >
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                </div>
            </template>
        </v-data-table>
        <!--create FORM-->
        <v-dialog
            v-model="openCreateForm"
            :retain-focus="false"
            persistent
            width="600px"
        >
            <UserForm
                v-if="openCreateForm"
                title="New Operator"
                :create-form="true"
                @closeDialog="closeEditOperator"
                @addUser="addOperator"
            />
        </v-dialog>
        <!--edition FORM-->
        <v-dialog
            v-model="openEditForm"
            :retain-focus="false"
            persistent
            width="600px"
        >
            <OperatorsForm
                v-if="openEditForm"
                :createForm="false"
                :operator="selectedOperator"
                :originalOperator="originalOperator"
                :processesSettings="processesSettings"
                @addOperator="addOperator"
                @replaceOperator="replaceOperator"
                @close="closeEditOperator"
            />
        </v-dialog>
    </v-container>
</template>

<script>
import { mapMutations } from 'vuex'
import API from '@/services/api'
import _ from 'lodash'
import cryptoJs from 'crypto-js'

export default {
    name: 'Operators',
    components: {
        OperatorsForm: () => import('@/components/Operators/OperatorsForm.vue'),
        UserForm: () => import('@/components/Users/UserForm.vue'),
    },
    data: () => ({
        loading: false,
        user: {},
        operators: [],
        selectedOperator: {},
        originalOperator: {},
        openCreateForm: false,
        openEditForm: false,
        openDeleteWarning: false,
        headers: [
            {
                text: 'NAME',
                value: 'name',
                sortable: false,
                align: 'left',
            },
            {
                text: 'ROLE',
                value: 'role',
                sortable: false,
                align: 'center',
            },
            {
                text: 'PROCESS',
                value: 'process',
                sortable: false,
                align: 'center',
            },
            {
                text: 'SIGN CODE',
                value: 'sign_code',
                sortable: false,
            },
            {
                text: 'ACTIONS',
                value: 'actions',
                sortable: false,
                align: 'center',
            },
        ],
        searchByName: undefined,
        processesSettings: {},
    }),
    async mounted() {
        try {
            const {
                data: { user },
            } = await API.getMyInfo()
            this.user = user
            const {
                data: { settings },
            } = await API.getSettings()
            this.processesSettings = settings.find(s => s.name == 'Processes')
            this.operators = await API.getOperators()
            this.operators.forEach(operator => {
                if (operator.signCode) {
                    operator.code = '******'
                } else {
                    operator.code = 'No sign code'
                }

                operator.showCode = false
            })
        } catch (error) {
            this.setErrorItems({
                name: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    computed: {
        operatorsFiltered() {
            const conditions = []
            if (this.operators && this.operators.length > 0) {
                if (this.searchByName) {
                    conditions.push(this.filterByName)
                }
            }

            if (conditions.length > 0) {
                return this.operators.filter(operator => {
                    return conditions.every(condition => {
                        return condition(operator)
                    })
                })
            }
            return this.operators
        },
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        filterByName(item) {
            return item.name
                .toLowerCase()
                .includes(this.searchByName.toLowerCase())
        },
        replaceOperator(operatorToUpdate) {
            const index = this.operators.findIndex(
                operator => operator.id == operatorToUpdate.id
            )
            if (index >= 0) {
                if (operatorToUpdate.operatorRole) {
                    this.operators.splice(index, 1, {
                        ...this.operators[index],
                        ...operatorToUpdate,
                    })
                } else {
                    this.operators.splice(index, 1)
                }
                this.returnSignCode(this.operators[index])
            }
        },
        addOperator(operator) {
            if (this.operators && this.operators.length > 0) {
                this.operators = [...this.operators, operator]
            } else {
                this.operators = [operator]
            }
            this.returnSignCode(operator)
        },
        removeOperator() {
            const index = this.operators.findIndex(
                operator => operator.id == this.selectedOperator.id
            )
            if (index >= 0) {
                this.operators.splice(index, 1)
            }
        },
        deleteUser: async function() {
            try {
                this.loading = true
                this.error = false
                this.errorMsg = null
                const { id, picture } = this.user
                await API.deleteUser(id)
                if (picture) await this.deleteImage(picture)
                const {
                    data: { documents },
                } = await API.getUserDocuments(id)
                this.documents = documents
                if (this.documents) {
                    this.documents.forEach(doc => {
                        this.deleteDocument(doc.file)
                    })
                    await API.deleteUserDocuments(id)
                }
                this.$emit('removeUser', id)
                this.closeDialog()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        closeEditOperator() {
            this.selectedOperator = {}
            this.originalOperator = {}
            this.openEditForm = false
            this.openCreateForm = false
        },
        openEditOperator(item) {
            const auxItem = _.cloneDeep(item)
            delete auxItem.showCode
            delete auxItem.code

            if (auxItem.signCode) {
                const bytes = cryptoJs.AES.decrypt(
                    auxItem.signCode,
                    process.env.VUE_APP_ENCRYPTION_PHRASE
                )
                auxItem.signCode = bytes.toString(cryptoJs.enc.Utf8)
            }

            this.createForm = false
            this.selectedOperator = _.cloneDeep(auxItem)
            this.originalOperator = _.cloneDeep(auxItem)
            this.openEditForm = true
        },
        openCreateOperator() {
            this.createForm = true
            this.openCreateForm = true
        },
        returnSignCode(operator) {
            operator.showCode = !operator.showCode

            if (operator.showCode) {
                const bytes = cryptoJs.AES.decrypt(
                    operator.signCode,
                    process.env.VUE_APP_ENCRYPTION_PHRASE
                )
                operator.code = bytes.toString(cryptoJs.enc.Utf8)
            } else {
                operator.code = '******'
            }
            const index = this.operators.findIndex(o => o.id == operator.id)
            this.$set(this.operators, index, _.cloneDeep(operator))
        },
    },
}
</script>

<style>
.v-data-table {
    border: 1px solid #eeeeee;
}
thead {
    background: #eeeeee;
}
.v-input .v-label {
    font-size: 12px;
}
</style>
